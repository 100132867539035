@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
	font-family: "Inter", sans-serif !important;
	font-optical-sizing: auto;
	font-style: normal;
	font-size: 14px !important;
}
.bg-white{
    background-color: #fff !important;
}
header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 9;
    box-shadow: 0px 2px 2px #80808082;
}
.slider-img {
	background: url('../public/images/bg-1.png');
	height: 600px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	position: relative;
}
.btn-orange{
	background: #CA262C !important;
    color: #fff !important;
    border-radius: 4px!important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 8px 25px !important;
    display: block;
    position: relative;
    z-index: 1;
}
.btn-orange:before {
    position: absolute;
    content: "";
    background-color: #160603;
    width: 101.5%;
    height: 0%;
    left: 50%;
    top: 50%;
    border-radius: 4px;
    transform: translate(-50%,-50%) rotate(0deg);
    z-index: -1;
    transition: all .5s ease;
}
.btn-orange:hover:before {
    height: 107%;
}
.banner-box {
    padding: 10px 5px;
}
.title {
	color: #FEFEFE;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    position: relative;
}
.title::before{
	content: "";
    width: 90px;
    height: 2px;
    background: #FEFEFE;
    position: absolute;
    top: 29px;
    border-radius: 10px;
}
.banner-box h2 {
	color: #fff;
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 50px;
}
.banner-box p {
	color: #fff;
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 20px;
}
.m-t-50 {
	margin-top: 50px;
}
.navbar-brand {
    margin-right: 50px !important;
}

.nav-item {
    padding: 0px 20px;
}
.nav-link {
	color: #000 !important;
	font-weight: 600 !important;
}
.certificate-img {
    position: absolute;
    right: 94px;
    bottom: -30px;
}
.space-section {
	padding: 45px 10px;
}
.heading {
	text-align: center;
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}
.heading span{
	color: #CA262C;
}
.service-slide {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}
.caption-img {
    padding: 25px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    color: #fff;
}
.caption-img h5 {
	font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.caption-img p {
	min-height: 80px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 300;
}
.counter-box {
    text-align: center;
    position: relative;
}
.counter-box h4 {
	color: #CA262C;
}
.counter-box p {
	color: #000;
}
.counter-box::after{
	content: "";
    width: 0.5px;
    height: 60px;
    background: #B7B3B3;
    position: absolute;
    top: 0;
    right: 0;
}
.bg-blue {
	background: #1C284F;
}
.text-left {
	text-align: left;
}
.coding-skill {
	background: url('../public/images/bg-3.png');
	color: #fff;
}
.erp-solution {
    padding: 30px 20px;
    border: 1px solid #C7C7C7;
    margin-bottom: 20px;
    width: 100%;
}
.icon {
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
    padding: 5px;
}
.erp-solution h5 {
    color: #252525;
    font-size: 16px;
    font-weight: 700;
}
.erp-solution p {
    min-height: 45px;
}
.icon-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 15px;
    padding-left: 0px;
}
.icon-list li {
    display: inline-block;
    width: 30%;
    padding: 5px 20px;
    text-align: center;
}
.contact-us {
    padding: 50px;
    box-shadow: 0px 2px 3px #8080808c;
    border-image: linear-gradient(to right, #590078 0%, #CA262C 100%) 1;
    border-radius: 5px;
    border-top: 4px solid;
}
.form-group {
    margin-bottom: 30px;
}
.form-group label {
	color: #646464;
	font-size: 16px;
	margin-bottom: 10px;
}
.form-control {
    border: 0px !important;
    border-bottom: 0px solid transparent !important;
    border-radius: 0px !important;
    position: relative;
}
.hr-line {
    background: #64646480;
    border-radius: 10px;
    width: 80%;
    height: 3px;
}
.m-t-30 {
	margin-top: 30px;
}
.footer {
    background: #000;
    color: #FEFEFE;
    font-weight: 300;
}
.footer .logo {
    max-width: 40px;
    margin-bottom: 15px;
}
.contact-icon {
	display: flex;
	align-items: center;
}
.contact-icon p{
	margin-bottom: 0px;
}
.contact-icon img {
	width: 20px;
	margin-right: 10px;

}
.footer-list {
	padding-left: 0px;
}
.footer-list li{
	display: block;
	margin-bottom: 15px;
}
.social-icon {
	padding-left: 0px;
}
.social-icon li {
	display: inline-block;
	padding: 0px 5px;
}

/*------------- About css -------------------*/

.about-bg {
	background-image: linear-gradient(to right, rgb(60 60 60), rgba(73, 73, 73, 0)), url('../public/images/about.png');
	height: 600px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	position: relative;
}
.icon.max-auto {
    margin: auto;
    margin-bottom: 30px;
}
.sub-heading {
	position: relative;
}
.sub-heading::after {
    content: "";
    width: 120px;
    height: 1px;
    background: linear-gradient(to right, rgba(202, 38, 44, 1), rgba(89, 0, 120, 1));
    position: absolute;
    top: 57px;
    text-align: center;
    left: 44%;
    right: 0;
}
.visions {
	background-image: linear-gradient(to right, #b8b8b8, #f2f3f300), url('../public/images/vision.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    padding: 110px 10px;
}
.author {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 1px solid #DFDEDE;
    top: -12px;
    z-index: 1;
    left: -5px;
    border-radius: 50%;
}
.author-img {
    background: #FEDA6ABD;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-right: 30px;
}
.author-box {
    position: relative;
    display: flex;
    align-items: center;
    top: 40px;
    margin-bottom: 60px;
}
.vision-title {
	position: relative;
	margin-bottom: 40px;
}
.vision-title::after {
    content: "";
    width: 80px;
    height: 2px;
    background: linear-gradient(to right, rgba(202, 38, 44, 1), rgba(89, 0, 120, 1));
    position: absolute;
    top: 39px;
    text-align: center;
    left: 0;
    right: 0;
}
.methodology-title {
	font-size: 26px;
    font-weight: 700;
    line-height: 40px;
}
.text-red{
    color: #CA262C !important;
}
.text-decoration{
    text-decoration: none;
}
/*------------- Our Work css ------------------- */

.our-work {
	background-image: linear-gradient(to right, rgb(0 0 0), rgba(73, 73, 73, 0)), url('../public/images/ourwork.png');
	height: 600px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	position: relative;
}
.bg-gray {
	background: #F4F7FD;
}
.demand-delivery {
	background: linear-gradient(to right, rgba(89, 0, 120, 1), rgba(202, 38, 44, 1));
    padding: 10px;
    font-size: 16px;
    width: 320px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;
}
.outcome-title {
	margin-bottom: 25px;
	text-transform: uppercase;
}
.outcome-description {
    padding: 10px;
    margin-bottom: 10px;
    border-image: linear-gradient(to right, #590078 30%, #CA262C 70%) 1;
    border-left: 3px solid;
    box-shadow: 1px 0px 2px #80808078;
}
.outcome-description p {
	margin-bottom: 0px;
}
.w-70 {
	width: 70%;
}
/*------------- Services css -------------------*/

.services-bg {
	background-image: linear-gradient(to right, rgb(60 60 60), rgba(73, 73, 73, 0)), url('../public/images/services.png');
	height: 600px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	position: relative;
}
.offer-title {
	font-weight: 300 !important;
}
.offer-title b {
	font-weight: 800 !important;
}

.rubyrail {
	background-image: linear-gradient(to right, rgb(60 60 60), rgba(73, 73, 73, 0)), url('../public/images/rubyonrail.png');
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
}
.rubyrail .outcome-description {
    background: rgb(255 255 255 / 52%) !important;
    border-left: 5px solid;
}

/*------------- Contact css -------------------*/

.contact-bg {
	background-image: url(../public/images/contact.png);
	height: 600px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	position: relative;
}
.contact-us.bg-light {
    border-radius: 0 !important;
    border-top: 0 !important;
    background: #fff !important;
}


.studies-box {
    position: relative;
    height: 300px;
    overflow: hidden;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.caption-slide {
    padding: 20px;
    color: #fff;
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, rgb(0 0 0 / 71%) 30%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
}
.caption-slide p{
	margin-bottom: 0px;
}
.studies-box img {
	width: 100%;
}

@media screen and (max-width:540px) {
  .icon-list {
      flex-wrap: wrap;
  }
  .icon-list li {
    width: 49%;
  }
  .banner-box h2 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .banner-box p {
    line-height: 20px;
    margin-bottom: 10px;
  }
  .m-t-50 {
    margin-top: 20px;
  }
  .heading {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .space-section {
    padding: 15px 10px;
  }
  .counter-box:after {
    display: none;
  }
  .banner-box {
    padding: 40px 5px;
  }
  .slider-img, .about-bg, .our-work, .services-bg {
    height: auto !important;
  }
  .sub-heading:after {
    background: linear-gradient(90deg,#ca262c,#590078);
    content: "";
    height: 2px;
    left: 30%;
    top: 75px;
  }
  .methodology-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .visions {
    padding: 30px 10px;
  }
  .contact-us {
    padding: 30px;
  }
  .nav-item {
    padding: 0px 0px;
    }
    .certificate-img {
        position: absolute;
        bottom: -30px;
        right: 5px;
        text-align: end;
    }
    .certificate-img img{
        max-width: 70%;
    }
    .title:before {
        background: #fefefe;
        border-radius: 10px;
        content: "";
        height: 2px;
        position: absolute;
        top: 40px;
        width: 90px;
    }
}

@media screen and (max-width:767px) and (min-width:541px) {
  .icon-list {
    flex-wrap: wrap;
  }
  .icon-list li {
    width: 30%;
  }
  .space-section {
    padding: 35px 10px;
  }
  .heading {
    font-size: 25px;
    margin-bottom: 25px;
    line-height: 40px;
  }
  .slider-img, .about-bg, .our-work, .services-bg {
    height: auto !important;
  }
  .space-section {
    padding: 20px 10px;
  }
  .sub-heading:after {
    left: 40%;
    top: 55px;
    width: 120px;
  }
  .nav-item {
    padding: 0px 0px;
    }
}
@media screen and (max-width:991px) and (min-width:767px) {
    .slider-img, .about-bg, .our-work, .services-bg {
        height: 470px !important;
      }
    .icon-list {
        flex-wrap: wrap;
        justify-content: unset !important;
    }
    .icon-list li {
        width: 24%;
        padding: 10px 10px !important;
    }
    .erp-solution {
        min-height: 300px;
    }
    .space-section {
        padding: 20px 10px;
    }
    .methodology-title {
        font-size: 20px;
        line-height: 30px;
    }
    .visions {
        padding: 30px 10px;
    }
    .heading {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .w-70 {
        width: 90% !important;
    }
}

.form-control:focus{
    box-shadow:none !important;
}
.col-md-3:last-child .counter-box::after {
  display: none;
}
.visibity-none.counter-box::after {
    display: none !important;
}
.success_msg{
    display: flex;
    justify-content: space-between;
    padding: 5px !important;
    align-items: center;
}
.success_msg button{
    background: transparent;
    border: 0;
    font-size: 18px;
}